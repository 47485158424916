import { iotDevice } from '@iso/services';
import { zoleoDevice } from '@iso/services';
import { spotDevice } from '@iso/services';

export const deviceFactory = {
  resolveService
};

function resolveService(key) {
  switch (key) {
    case 'airagri_pld':
    case 'iot_device':
    case 'IOT_devices':
    case 'iot_devices':
      return iotDevice;

    case 'zoleo_airagri':
    case 'zoleo_device':
    case 'property_zoleo_devices':
      return zoleoDevice;
    case 'spot':
      return spotDevice;
  
    default:
      break;
  } 

  return this;
}