export const sensorPartNumbers = [
  {
    label: 'X50-14002',
    value: 'X50-14002',
  },
  {
    label: 'X50-14016',
    value: 'X50-14016',
  },
  {
    label: 'X50-14010',
    value: 'X50-14010',
  },
  {
    label: 'X50-14006',
    value: 'X50-14006',
  },
  {
    label: 'X50-14019',
    value: 'X50-14019',
  },
  {
    label: 'X50-14022',
    value: 'X50-14022',
  },
  {
    label: 'X50-14023',
    value: 'X50-14023',
  },
  {
    label: 'X50-14024',
    value: 'X50-14024',
  }
]