import React, { useState, useEffect } from 'react';
import {ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import CardSection from './CardSection';
import stripeActions from '@iso/redux/stripe/actions';
import {useDispatch } from 'react-redux';
const {endTriggerStripe, setToken, setCardToken, setError} = stripeActions;

const CheckoutForm = (props) => {
  const stripeTrigger = useSelector((state) => state.stripe.stripeTrigger);
  const {stripe, elements} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if(stripeTrigger){
      async function triggerStripe(){
        const cardElement = elements.getElement(CardElement);
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
        const cardToken = await stripe.createToken(cardElement);

        if (error || cardToken.error) {
          console.log('[error]', error);
          console.log('[tokenError]', cardToken.error);
          dispatch(setError(error || cardToken.error));
          dispatch(endTriggerStripe());
        } else {
          dispatch(setToken(paymentMethod.id));
          dispatch(setCardToken(cardToken.token.id));
        }
        
      }
      triggerStripe();
    }
  }, [stripeTrigger]);

  return(
    <>
      <CardSection />      
    </>  
  )
}



export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm  stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}