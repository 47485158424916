import stripeActions from './actions';

const initialState = {
  stripeTrigger: false,
  token: null,
  cardToken: null,
  error: null
};

export default function stripeReducer(state = initialState, action) {
  switch (action.type) {
    case stripeActions.TRIGGER_STRIPE:
      return {
        stripeTrigger: true
      };
    case stripeActions.END_TRIGGER_STRIPE:
      return {
        stripeTrigger: false
      };
    case stripeActions.SET_TOKEN: 
      return {
        token: action.token
      };
    case stripeActions.SET_CARD_TOKEN: 
      return {
        cardToken: action.cardToken
      };    
    case stripeActions.SET_ERROR: 
      return {
        error: action.error
      };
    default:
      return state;
  }
}
