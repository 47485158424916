import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row, DatePicker, Spin, Select, Button, Flex } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from "@iso/components/utility/layoutWrapper.js";
import SensorsPage from "./Sensors.styles";
import moment from 'moment-timezone';
import { BellTwoTone } from '@ant-design/icons';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar,
    Rectangle
} from "recharts";
import { sensorService } from '../../../services/sensor.service';
import { deviceSensorService } from '../../../services/deviceSensor.service';
import IntlMessages from '@iso/components/utility/intlMessages';
import { EditOutlined } from '@ant-design/icons';
import EditSensorNameModal from './EditSensorNameModal';
import { useSelector} from 'react-redux';
import { userService } from '@iso/services';

const { Option } = Select; 
const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

const defaultTimelineOption = 'last_24_hours';

const Sensors = (props) => {

    const activePropertyId = useSelector(
        (state) => state.property.activePropertyId
    );
    const activeProperty = JSON.parse(localStorage.getItem('active_property'));
    const propertyTimezone = activeProperty.timezone ? activeProperty.timezone : 'UTC';

    const [selectedTimezone, setSelectedTimezone] = useState(propertyTimezone);

    const rangePickerDateFormat = 'DD/MM/YYYY';
    const defaultStartDate = moment().tz(selectedTimezone).subtract(23, 'hours').subtract(59, 'minutes').format(dateFormat);
    const defaultEndDate = moment().tz(selectedTimezone).format(dateFormat);
  
    const [sensors, setSensors] = useState([]);
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);
    const [timelineOption, setTimelineOption] = useState(defaultTimelineOption);
    const [editSensorNameModalVisible, setEditSensorNameModalVisible] = useState(false);
    const [editSensor, setEditSensor] = useState({});
    const [statsSensor, setStatsSensor] = useState({});
    const [typeModalSensor, setTypeModalSensor] = useState('edit');
    const [ sensorType, setSensorType] = useState('temperature');
    const [usersProperty, setUsersProperty] = useState([]);
    useEffect(() => {
        if(activePropertyId){
            getDevices(activePropertyId,startDate,endDate,selectedTimezone);
            getUsers(activePropertyId);
        }
    }, [activePropertyId,startDate,endDate]);
    
    useEffect(() => {
        if(devices.length > 0){
            setSensors(devices.flatMap(device => device.sensors));
        }
    }, [devices]);

   
    const getUsers = (propertyId) => {
        userService.getUserForProperty(propertyId).then(res => {
          if(res.code === '0000'){
            setUsersProperty(res.users);
          }
        });
      }

    const renderSensors = useCallback(() => {
        if (!sensors || sensors.length === 0) return null;
    
        const filteredSensors = sensors.filter(sensor => {
            const hasStatsData = sensor.stats && Object.keys(sensor.stats).length > 0;
            const hasTimelineData = sensor.timelines && Object.keys(sensor.timelines).length > 0;
            return hasStatsData || hasTimelineData; 
        });
    
        return filteredSensors.map((sensor, index) => (
            <Row gutter={15} className="manage-table-row" key={sensor.terminal_id || index} style={{ marginBottom: '15px' }}>
                <Col xs={24}>
                    <p>Device ID: {sensor.terminal_id}</p>
                    <p>Sensor Name: {sensor.name} <EditOutlined onClick={() => handleEditSensor(sensor,'','edit')}/></p>
                    <p>Asset: {sensor.asset?.title || 'N/A'}</p>
                </Col>
                <Col xl={6} md={24} sm={24} xs={24}>
                    {renderStatsChart(sensor.stats)} 
                </Col>
    
                <Col xl={18} md={24} sm={24} xs={24}>
                    {renderTimelineChart(sensor)}
                </Col>
            </Row>
        ));
    }, [sensors]);
    

    const renderStatsChart = (stats) => {
        if (!stats) return null;
    
        return Object.keys(stats).map((key) => {
          const stat = stats[key];
          const barChartData = [
            { label: 'Avg', value: stat.avg },
            { label: 'Min', value: stat.min },
            { label: 'Max', value: stat.max }
          ];
    
          const unit = stat.unit || ''; 
    
          return (
            <div style={{marginBottom: '30px'}}>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={barChartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="label" />
                        <YAxis unit={unit} />
                        <Tooltip formatter={(value) => `${value}${unit}`} />
                        <Legend />
                        <Bar
                            name={stat.label}
                            dataKey="value"
                            fill="#0B2A1B"
                            activeBar={<Rectangle fill="pink" stroke="blue" />}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
          );
        });
      };
      
      const renderTimelineChart = (sensor) => {
        const { timelines } = sensor;
        if (!timelines?.length) return null;
        const sensorDataMap = timelines.reduce((acc, timeline) => {
            for (let sensorType in timeline) {
                acc[sensorType] = acc[sensorType] || [];
                acc[sensorType].push(timeline[sensorType]);
            }
            return acc;
        }, {});

        const lineChart = (data, sensorType) => (
            <div style={{marginBottom: '30px'}}>
                <Button shape="circle" style={{ float: 'right',marginTop: '-30px' }} onClick={() => handleEditSensor(sensor,sensorType,'alarm')}>
                    <BellTwoTone twoToneColor="#0B2A1B"/>
                </Button>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis unit={data[0]?.unit || ''} />
                        <Tooltip formatter={(value) => `${value}${data[0]?.unit || ''}`} />
                        <Legend />
                        <Line
                            name={capitalizeFirstLetter(sensorType)}
                            type="monotone"
                            dataKey="value"
                            stroke="#0B2A1B"
                            activeDot={{ r: 8 }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );

        return (
            <div>
                {Object.entries(sensorDataMap).map(([sensorType, data]) =>
                    lineChart(data, sensorType)
                )}
            </div>
        );
    };

    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const getDevices = (property_id,start_date,end_date, timezone) => {
        setLoading(true);

        const params = { start_date, end_date, property_id, timezone };

        deviceSensorService.getList(params).then((res) => {
            if (res.code === "0000") {
                setDevices(res.devices);
                setLoading(false);
            }
        });
    }

    const renderTimezoneSelect = () => {
        return (
            <Select
                style={{ width: 170, marginRight: 10 }}
                // disabled={true}
                defaultValue={selectedTimezone}
            >
                <Option value={selectedTimezone}>{selectedTimezone}</Option>
            </Select>
        );
    };

    const getTimelineOptions = () => {
        return [
            { value: 'last_24_hours', label: 'Last 24 hours' },
            { value: 'today', label: 'Today' },
            { value: 'yesterday', label: 'Yesterday' },
            { value: 'last_7_days', label: 'Last 7 days' },
            { value: 'last_30_days', label: 'Last 30 days' },
            { value: 'this_month', label: 'This month' },
            { value: 'last_month', label: 'Last month' },
            { value: 'custom', label: 'Custom' },
        ];
    };

    const timelineOptions = {
        last_24_hours: {
            startDate: moment().tz(selectedTimezone).subtract(23, 'hours').subtract(59, 'minutes'),
            endDate: moment().tz(selectedTimezone)
        },
        today: {
            startDate: moment().tz(selectedTimezone).startOf('day'),
            endDate: moment().tz(selectedTimezone).endOf('day')
        },
        yesterday: {
            startDate: moment().tz(selectedTimezone).subtract(1, 'days').startOf('day'),
            endDate: moment().tz(selectedTimezone).subtract(1, 'days').endOf('day')
        },
        last_7_days: {
            startDate: moment().tz(selectedTimezone).subtract(7, 'days'),
            endDate: moment().tz(selectedTimezone)
        },
        last_30_days: {
            startDate: moment().tz(selectedTimezone).subtract(30, 'days'),
            endDate: moment().tz(selectedTimezone)
        },
        this_month: {
            startDate: moment().tz(selectedTimezone).startOf('month'),
            endDate: moment().tz(selectedTimezone).endOf('month')
        },
        last_month: {
            startDate: moment().tz(selectedTimezone).subtract(1, 'month').startOf('month'),
            endDate: moment().tz(selectedTimezone).subtract(1, 'month').endOf('month')
        }
    };

    const onTimelineOptionChange = (value) => {
        setTimelineOption(value);

        if (value !== 'custom' && timelineOptions[value]) {
            const { startDate, endDate } = timelineOptions[value];
            setStartDate(startDate.format(dateFormat));
            setEndDate(endDate.format(dateFormat));
        }
    };


    const handleDateChange = (date, dateString) => {
        setStartDate(date[0].startOf('day').format(dateFormat));
        setEndDate(date[1].endOf('day').format(dateFormat));
    };

  
    const cancelEditSensorNameModal = () => {
        setEditSensorNameModalVisible(false);
    }

    const handleEditSensor = (sensor, sensorType, typeModal) => {
        setEditSensor(sensor);
    
        setStatsSensor(typeModal === 'alarm' ? sensor?.stats[sensorType] : {});
        setSensorType(sensorType);
        setTypeModalSensor(typeModal);
        setEditSensorNameModalVisible(true);
    };

   
    const onEditSensorNameSaved = (value) => {
        const params = {
            sensor: {
                name: value
            }
        };
        sensorService.storeOrUpdate(params, editSensor.id).then(res => {
            if (res.code === '0000') {
                setSensors(prevSensors => 
                    prevSensors.map(sensor => 
                        sensor.id === editSensor.id 
                            ? { ...sensor, name: value }
                            : sensor 
                    )
                );
                setEditSensorNameModalVisible(false);
            }
        });
    }

    return (
        <LayoutWrapper>
            <SensorsPage>
                <Box id="containerBox">
                    <div className="manage-property-header">
                        <div className="mp-title">
                            <h2 className="manage-property-title"><IntlMessages id="sensorPage.title" /></h2>
                        </div>
                            
                        <div className="button-group">
                            { renderTimezoneSelect() }

                            <Select style={{ width: 140 }} defaultValue={timelineOption} onChange={onTimelineOptionChange}>
                                { getTimelineOptions().map((option, index) => (
                                    <Option key={index} value={option.value}>{option.label}</Option>
                                )) }
                            </Select>
                            
                            { timelineOption === 'custom' && <RangePicker
                                style={{marginLeft: '15px'}}
                                format={rangePickerDateFormat}
                                onChange={handleDateChange}
                            /> }
                        </div>
                    </div>

                    <div style={{padding: '15px'}}>
                        { loading ? (
                            <Spin style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} size="large" />
                        ) : (
                            <div>
                                {renderSensors()}
                            </div>
                        )}
                    </div>
                </Box>
            </SensorsPage>
            
            <EditSensorNameModal
                visible={editSensorNameModalVisible}
                sensor={editSensor}
                statsSensor={statsSensor}
                usersProperty={usersProperty}
                sensorType={sensorType}
                typeModalSensor={typeModalSensor}
                onEditSensorNameSaved={onEditSensorNameSaved}
                onCancel={cancelEditSensorNameModal}
            />
        </LayoutWrapper>
    )
};

export default Sensors;