import React , { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Row, Col, Select } from 'antd';
import { propertyService } from '@iso/services';
import { device } from '@iso/services';
import IntlMessages from '@iso/components/utility/intlMessages';
import AssignModalWrapper from './AssignModal.styles';
import _ from 'lodash';
import Alert from '@iso/components/Feedback/Alert';

const { Option } = Select;

const AssignModal = (props) => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const currentUser = useSelector((state) => state.user.user);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    propertyService.getListUsersOfProperty(props.propertyId).then((res)=>{
      setUsers(res.users)
    })
  }, []);

  useEffect(() => {
    setFields([
      {
        name: 'user_id',
        value: props.assignedDevice?.user_id,
      },
    ]);
  }, [props, currentUser]);

  const cancelModal = () => {
    props.cancelModal();
    resetForm();
  }

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        device.storeOrUpdate({user_id: values.user_id}, props.assignedDevice?.id).then(()=>{
          props.cancelModal();
          setLoading(false);
          props.onSaved();
        })
      })
      .catch(info => {
        setLoading(false);
      });
  }

  const resetForm = () => {
    setTimeout(() => {
      form.resetFields();
      setLoading(false);
      setErrMsg("");
    }, 500);
  }

  const renderOptionsAcreageUnit = () => {
    let options = [];
    _.forEach(users, (user, index) => {
      options.push(
        <Option key={index} value={user.id}>
            <div>{user.name}</div>
        </Option>
      );
    })
    return (
      <Select
        placeholder="Select a user"
        allowClear
        size="large"
      >
        {options}
      </Select>
    );
  }

  return (
    <AssignModalWrapper
      forceRender={true}
      visible={props.visible}
      title={<IntlMessages id="safeFarmPage.assign.modal.title" />}
      onCancel={cancelModal}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={cancelModal} className="cancel-button">
          {<IntlMessages id="propertyPage.modal.cancel" />}
        </Button>,
        <Button key="submit" className="btn-success" type="primary" loading={loading} onClick={handleSubmit}>
          {<IntlMessages id="propertyPage.modal.save" />}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="assignUserToIotDevice"
        layout="vertical"
        scrollToFirstError
        initialValues={{
          use_my_account: false,
        }}
        fields={fields}
      >
        {
          errMsg &&
          <Alert
            message={errMsg}
            type="error"
            style={{margin: '15px 15px 0 15px'}}
          />
        }
        <div className="form-body">
          <Form.Item
            name="user_id"
            className="isoInputWrapper"
            label="User"
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
            ]}
          >
          {renderOptionsAcreageUnit()}
          </Form.Item>
        </div>
      </Form>
    </AssignModalWrapper>
  );
}

export default AssignModal;
