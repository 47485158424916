import React, { useState, useEffect } from "react";
import IntlMessages from "@iso/components/utility/intlMessages";
import PrimaryObjectModalWrapper from "./PrimaryObjectModal.styles";
import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Popconfirm,
  Row,
  Col,
  Tabs,
  Upload,
  Switch,
  Space,
  Collapse,
  
} from "antd";
import { UploadOutlined,MinusCircleOutlined,
  PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import { objectColors } from "@iso/constants/objectColors";
import { objectTypes } from "@iso/constants/objectTypes";
import styled from "styled-components";
import {
  primaryObjectService,
  cropService,
  primaryObjectTypeService,
  activityService,
  calculatorPaddock,
} from "@iso/services";
import modalActions from "@iso/redux/modal/actions";
import { useSelector, useDispatch } from "react-redux";
import CropModalWrapper from "./CropModal.styles";
import Loader from "@iso/components/utility/loader";
import ObjectTypeModalWrapper from "./ObjectTypeModal.styles";
import PropertyAreaHistory from "@iso/containers/Pages/PropertyArea/PropertyAreaHistory";
import AddAreaHistoryModal from "./AddAreaHistoryModal";
import AddPlanModal from "@iso/containers/Pages/PropertyMap/components/Plan/AddPlanModal";
import Paddock from "@iso/containers/Pages/PropertyMap/components/Plan/Paddock";
import $ from "jquery";
import { validationRegex } from '@iso/constants/validation';
import { apiEndpoint, apiEndpointFunction } from "@iso/lib/helpers/endpoint";
import { authHeader } from "@iso/lib/helpers/authHeader";
import FileUploader from "@iso/components/FileUploader/FileUploader";
import { FileStorage } from "@iso/constants/UploadFile";
import TextArea from "antd/lib/input/TextArea";
import { propertyHelper } from "@iso/lib/helpers/propertyHelper";

const { Option } = Select;
const { closeModal } = modalActions;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const PrimaryObjectModal = (props) => {
  const [form] = Form.useForm();
  const [cropForm] = Form.useForm();
  const [primaryObjectTypeForm] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const modalVisibility = useSelector((state) => state.modal.modalVisibility);
  const modalData = useSelector((state) => state.modal.modalData);
  const dispatch = useDispatch();
  const [dataObject, setDataObject] = useState({});
  const [crops, setCrops] = useState({});
  const [primaryObjectTypes, setPrimaryObjectTypes] = useState({});
  const [loadingCrop, setLoadingCrop] = useState(false);
  const [visibleCrop, setVisibleCrop] = useState(false);
  const [visibleObjectType, setVisibleObjectType] = useState(false);
  const [loadingObjectType, setLoadingObjectType] = useState(false);
  const activePermission = useSelector(
    (state) => state.permission.activePermission
  );
  const activePropertySubscription = useSelector(
    (state) => state.subscription.activePropertySubscription
  );
  const [propertyAreaHistory, setPropertyAreaHistory] = useState([]);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [dataPlan, setDataPlan] = useState([]);
  const [paddockId, setPaddockId] = useState("");
  const [paddockObj, setPaddockObj] = useState({});
  const [stockObj, setStockObj] = useState({});
  const [resultObj, setResultObj] = useState({});
  const [visiblePlan, setVisiblePlan] = useState(false);
  const [dataPaddock, setDataPaddock] = useState([]);
  const [isAllow, setIsAllow] = useState(0);


  useEffect(() => {
    if (props.propertyId) {
      cropService.getList(props.propertyId).then((res) => {
        if (res.code === "0000") {
          setCrops(res.crops);
          setLoading(false);
        }
      });
    }
  }, [props.propertyId]);
  var initialValue;
  useEffect(() => {
    initialValue = form.getFieldValue(["primary_object", "is_active"]);
  }, []);

  const updateDataAreaHistory = React.useCallback(
    (primary_object_id, modal_type) => {
      activityService.getList(primary_object_id, modal_type).then((res) => {
        if (res.code === "0000") {
          setPropertyAreaHistory(res.activities.reverse());
        }
      });
    },
    [propertyAreaHistory]
  );

  useEffect(() => {
    primaryObjectTypeService.getListNomal(props.propertyId).then((res) => {
      if (res.code === "0000") {
        setPrimaryObjectTypes(res.primary_object_types);
        setLoading(false);
      }
    });
  }, [props.propertyId]);

  useEffect(() => {
    if (dataObject) {
      updateDataAreaHistory(dataObject.id, "area");
      getListPaddock();
    }
  }, [props.propertyId, dataObject]);

  useEffect(() => {
    if (modalData.type === "area") {
      if (modalData.object) {
        setDataObject(modalData.object);
      }
let coordinates = modalData?.object?.coordinates || modalData?.object?.area;
      const formattedCoordinations = [];
      if(coordinates){
        coordinates.map(coordinate => {
          formattedCoordinations.push({
            lat: coordinate[0],
            lng: coordinate[1],
          })
        })
      }

      setIsAllow(modalData.object.is_active);

      setFields([
        {
          name: ["primary_object", "name"],
          value: modalData.object.name || "",
        },
        {
          name: ["primary_object", "color"],
          value: modalData.object.color || "blue",
        },
        {
          name: ["primary_object", "type_id"],
          value: modalData.object.type_id || "",
        },
        {
          name: ["primary_object", "acreage"],
          value: modalData.object.acreage
            ? parseFloat(modalData.object.acreage)
            : 0,
        },
        {
          name: ["primary_object", "description"],
          value: modalData.object.description || "",
        },
        {
          name: ["primary_object", "crop"],
          value: modalData.object.crop || "",
        },
        {
          name: ["primary_object", "is_active"],
          value: modalData.object.is_active,
        },
        {
          name: ['coordinations'],
          value: formattedCoordinations,
        },
        {
          name: ['primary_object', 'speed_limit'],
          value: modalData.object.speed_limit,
        },
        {
          name: ['primary_object', 'time_limit'],
          value: modalData.object.time_limit,
        },
      ]);
    }
  }, [modalData]);

  const handleCropSubmit = (e) => {
    cropForm
      .validateFields()
      .then((crop_values) => {
        onStoreCrop(crop_values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoadingCrop(false);
      });
  };

  const addCrop = (object, e) => {
    setVisibleCrop(true);
  };
  const getListPaddock = () => {
    calculatorPaddock.getList(dataObject.id).then((res) => {
      if (res.code === "0000") {
        setDataPaddock(res.paddock);
      }
    });
  };
  const onStoreCrop = (crop_values) => {
    const crop_data = {
      crop: {
        ...crop_values.crop,
        property_id: props.propertyId,
      },
    };

    cropService.storeOrUpdate(crop_data).then((res) => {
      if (res.code === "0000") {
        var tmp_crops = _.clone(crops);
        tmp_crops.push(res.crop);
        setCrops(tmp_crops);
        cancelModalCrop();
        cropForm.resetFields();
        setFields([
          {
            name: ["primary_object", "crop"],
            value: res.crop.id,
          },
        ]);
      } else {
        setLoadingCrop(false);
      }
    });
  };

  const handleObjectTypeSubmit = (e) => {
    primaryObjectTypeForm
      .validateFields()
      .then((object_type_values) => {
        onStorePrimaryObjectType(object_type_values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoadingObjectType(false);
      });
  };

  const onStorePrimaryObjectType = (object_type_values) => {
    const object_type_data = {
      primary_object_type: {
        ...object_type_values.primary_object_type,
        property_id: props.propertyId,
      },
    };

    primaryObjectTypeService.storeOrUpdate(object_type_data).then((res) => {
      if (res.code === "0000") {
        var tmp_object_types = _.clone(primaryObjectTypes);
        tmp_object_types.push(res.primary_object_type);
        setPrimaryObjectTypes(tmp_object_types);
        cancelModalObjectType();
        primaryObjectTypeForm.resetFields();
        setFields([
          {
            name: ["primary_object", "type_id"],
            value: res.primary_object_type.id,
          },
        ]);
      } else {
        setLoadingObjectType(false);
      }
    });
  };

  const addPrimaryObjectType = (object, e) => {
    setVisibleObjectType(true);
  };

  const cancelModalObjectType = () => {
    setVisibleObjectType(false);
    primaryObjectTypeForm.resetFields();
  };

  const cancelModalCrop = () => {
    setVisibleCrop(false);
    cropForm.resetFields();
  };

  const cancelPlanModal = () => {
    setVisiblePlan(false);
  };

  const cancelModal = () => {
    dispatch(closeModal());
    if (modalData.layer) {
      modalData.featureGroup.leafletElement.removeLayer(modalData.layer);
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        values.primary_object.property_id = props.propertyId;
        let coordinates = modalData.object.coordinates || modalData.object.area;
        values.primary_object.data_area = _.map(coordinates, (point) => {
          return point.toString().replace(",", ", ");
        });

        onStore(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const onStore = (values) => {
    switch (props.propertyAcreageUnit) {
      case "acres":
        var acreage_by_acres = values.primary_object.acreage;
        var acreage_by_hectare = (acreage_by_acres / 2.471).toFixed(2);
        var acreage_by_yard = (acreage_by_hectare * 11959.9005).toFixed(2);
        break;
      case "hectare":
        var acreage_by_hectare = values.primary_object.acreage;
        var acreage_by_acres = (acreage_by_hectare * 2.471).toFixed(2);
        var acreage_by_yard = (acreage_by_hectare * 11959.9005).toFixed(2);
        break;
      case "yard":
        var acreage_by_yard = values.primary_object.acreage;
        var acreage_by_hectare = (acreage_by_yard / 11959.9005).toFixed(2);
        var acreage_by_acres = (acreage_by_hectare * 2.471).toFixed(2);
        break;
      default:
        var acreage_by_hectare = values.primary_object.acreage;
    }
    const data = {
      primary_object: {
        ...values.primary_object,
        image_id: imageId,
        acreage_by_acres: acreage_by_acres,
        acreage_by_hectare: acreage_by_hectare,
        acreage_by_yard: acreage_by_yard,
        is_active: isAllow,
      },
    };

    primaryObjectService
      .storeOrUpdateProperties(data, modalData.object.id)
      .then((res) => {
        if (res.code === "0000") {
          cancelModal();
          if (modalData.object.id) {
            props.onEdited(res.primary_object);
          } else {
            props.updatePropertyDetail(props.propertyId);
          }
          setLoading(false);
          form.resetFields();
          // props.resetLayer();
        } else {
          setLoading(false);
        }
      });
  };

  const renderOptionsColor = () => {
    let options = [];
    _.forEach(objectColors, (color, index) => {
      options.push(
        <Option key={index} value={color.value}>
          <ColorItemRow>
            <ColorItemPreview
              style={{ backgroundColor: color.color }}
            ></ColorItemPreview>
            <div>{color.label}</div>
          </ColorItemRow>
        </Option>
      );
    });
    return (
      <Select placeholder="Select a color" allowClear size="large">
        {options}
      </Select>
    );
  };

  const renderOptionsType = () => {
    let options = [];
    _.forEach(objectTypes, (type, index) => {
      options.push(
        <Option key={index} value={type.value}>
          <ColorItemRow>
            <div>{type.label}</div>
          </ColorItemRow>
        </Option>
      );
    });
    return (
      <Select placeholder="Select a type" allowClear size="large">
        {options}
      </Select>
    );
  };

  const renderOptionsCrop = (object_crops) => {
    let crop_options = [];
    _.forEach(object_crops, (crop_opt, index) => {
      crop_options.push(
        <Option key={index} value={crop_opt.id}>
          <div>{crop_opt.name}</div>
        </Option>
      );
    });
    return (
      <Select placeholder="Select a crop" allowClear size="large">
        {crop_options}
      </Select>
    );
  };

  const renderOptionsPrimaryObjectType = (object_types) => {
    let object_type_options = [];
    _.forEach(object_types, (type_opt, index) => {
      object_type_options.push(
        <Option key={index} value={type_opt.id}>
          <div>{type_opt.name}</div>
        </Option>
      );
    });
    return (
      <Select placeholder="Select a type" allowClear size="large">
        {object_type_options}
      </Select>
    );
  };

  const handleDelete = (object) => {
    primaryObjectService.destroy(object.id).then((res) => {
      if (res.code === "0000") {
        cancelModal();
        props.updatePropertyDetail(props.propertyId);
      }
    });
  };

  const editArea = () => {
    cancelModal();
    props.editArea(dataObject.clickedPolygon);
  };

  const [activeTab, setActiveTab] = useState("1");

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  const addHistory = (object, e) => {
    setVisibleHistory(true);
  };
  const addPlan = (object, e) => {
    setVisiblePlan(true);
  };

  const cancelModalHistory = () => {
    setVisibleHistory(false);
  };

  const handleOnUploadSuccess = (image) => {
    setImageId(image.id);
  };
  const handleChangeAdHoch = (checked) => {
    setIsAllow(checked ? 1 : 0);
  };
  
  return (
    <>
      <PrimaryObjectModalWrapper
        className="user-access-modal"
        forceRender={true}
        visible={modalVisibility && modalData.type === "area"}
        title={
          dataObject.name ? (
            modalData.viewHistoryVisible ? (
              <IntlMessages id="View History" />
            ):
           ( <IntlMessages id="propertyMapPage.editObject" />)
          
          ) : (
            <IntlMessages id="propertyMapPage.addObject" />
          )
        }
        onCancel={cancelModal}
        maskClosable={false}
        footer={[
          !modalData.viewHistoryVisible ? (
            <>
             {  dataObject.name && !modalData.hideEditArea && (
            <Button
              key="editArea"
              onClick={editArea}
              style={{ float: "left" }}
              className="cancel-button"
            >
              {<IntlMessages id="propertyPage.modal.editArea" />}
            </Button>
          )}
          <Button key="back" onClick={cancelModal} className="cancel-button">
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>
          {(activePermission == "owner" ||
            activePermission == "admin" ||
            activePermission == "modify") && (
            <Button
              key="submit"
              className="btn-success"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              {<IntlMessages id="propertyPage.modal.save" />}
            </Button>
          )}
          {(activePermission == "owner" || activePermission == "admin") &&
            dataObject.name && (
              <Popconfirm
                placement="bottomRight"
                title={"You are about to delete this object"}
                onConfirm={handleDelete.bind(this, dataObject)}
                okText="Yes"
                cancelText="No"
                className="delete-button"
              >
                <Button key="delete" type="danger" style={{height:'auto'}}>
                  {<IntlMessages id="propertyPage.modal.delete" />}
                </Button>
              </Popconfirm>
            )}
            </>
        ):null
         
        ]}
      >
        <Tabs
          defaultActiveKey="1"
          className="form-body"
          onChange={onChangeTab}
          activeKey={activeTab}

        >
         {!modalData.viewHistoryVisible === true ? (
          <>
          <TabPane
            tab={<IntlMessages id="propertyPage.mobModal.detailTab" />}
            key="1"
          >
            {
              <Form
                form={form}
                name="addEditPrimaryObject"
                layout="vertical"
                scrollToFirstError
                fields={fields}
              >
                <div>
                  <Form.Item
                    name={["primary_object", "name"]}
                    className="isoInputWrapper"
                    label="Paddock Name"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                      {
                        whitespace: true,
                        message: "Please input your Name",
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Input your object name" />
                  </Form.Item>
                  <Row>
                    <Col xs={{ span: 15 }} md={{ span: 17 }}>
                      <Form.Item
                        name={["primary_object", "crop"]}
                        className="isoInputWrapper"
                        label="Crop"
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        {renderOptionsCrop(crops)}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 8, offset: 1 }}
                      md={{ span: 6, offset: 1 }}
                    >
                      <Button
                        key="submit"
                        className="btn-create-crop"
                        type="primary"
                        onClick={addCrop}
                      >
                        {<IntlMessages id="+ Add New Crop" />}
                      </Button>
                    </Col>
                  </Row>
                  <AcreageInput>
                    <Form.Item
                      name={["primary_object", "acreage"]}
                      className="isoInputWrapper"
                      label="Acreage"
                      rules={[
                        {
                          required: true,
                          message: "This field is required",
                        },
                        {
                          type: "number",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <InputNumber
                        disabled={true}
                        size="large"
                        placeholder="Input your object acreage"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <SuffixAddon>
                      {props.propertyAcreageUnit
                        ? props.propertyAcreageUnit
                        : "hectare"}
                    </SuffixAddon>
                  </AcreageInput>
                  <Form.Item
                    name={["primary_object", "color"]}
                    label="Colour on map"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    {renderOptionsColor()}
                  </Form.Item>
                  <Row>
                    <Col xs={{ span: 15 }} md={{ span: 17 }}>
                      <Form.Item
                        name={["primary_object", "type_id"]}
                        className="isoInputWrapper"
                        label="Type to Usage"
                        rules={[
                          {
                            required: true,
                            message: "This field is required",
                          },
                        ]}
                      >
                        {renderOptionsPrimaryObjectType(primaryObjectTypes)}
                      </Form.Item>
                    </Col>
                    <Col
                      xs={{ span: 8, offset: 1 }}
                      md={{ span: 6, offset: 1 }}
                    >
                      <Button
                        key="submit"
                        className="btn-create-crop"
                        type="primary"
                        onClick={addPrimaryObjectType}
                      >
                        {<IntlMessages id="+ Add New Type" />}
                      </Button>
                    </Col>
                  </Row>

                  <Form.List 
                    name="coordinations" 
                    label="Coordinates"
                    rules={[
                      {
                        validator: async (_, coordinations) => {
                          if (!coordinations || coordinations.length < 3) {
                            return Promise.reject(new Error('At least 3 points'));
                          }
                        },
                      },
                    ]}
                  >
                    {(fields, { add, remove }, { errors }) => (
                      <>
                      <Collapse defaultActiveKey={['0']} style={{marginBottom:'1rem'}} >
                      <Panel header="Area Coordinates" key="1" >
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                              {...restField}
                              name={[name, 'lat']}
                              rules={[
                                { required: true, message: 'Missing Latitude' },
                                {
                                  validator: async (_, lat) => {
                                    if(lat){
                                      if(!lat.toString().match(validationRegex['coordinates'])){
                                        return Promise.reject(new Error('Latitude is invalid format'));
                                      }
                                    }
                                  },
                                }
                              ]}
                            >
                              <Input placeholder="Latitude" />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, 'lng']}
                              rules={[
                                { required: true, message: 'Missing Longitude' },
                                {
                                  validator: async (_, lng) => {
                                    if(lng){
                                      if(!lng.toString().match(validationRegex['coordinates'])){
                                        return Promise.reject(new Error('Longitude is invalid format'));
                                      }
                                    }
                                  },
                                }
                              ]}
                            >
                              <Input placeholder="Longitude" />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Point
                          </Button>
                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                        </Panel>
                        </Collapse>
                      
                      </>
                    )}
                  </Form.List>

                  <Row gutter={16}>
                    <Col xs={{span: 24}} md={{span: 12}}>
                        <Form.Item
                          name={['primary_object', 'speed_limit']}
                          className="isoInputWrapper"
                          label="Speed Limit (kn)"
                        >
                          <Input size="large" placeholder="Input speed limit"/>
                        </Form.Item>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 12}}>
                      <Form.Item
                        name={['primary_object', 'time_limit']}
                        className="isoInputWrapper"
                        label="Time Limit (minutes)"
                      >
                        <Input size="large" placeholder="Input time limit"/>
                      </Form.Item>
                    </Col>
                  </Row>
              
                  <Form.Item
                    name={["primary_object", "description"]}
                    label="Description"
                    placeholder="Write..."
                  >
                    {/* <Input.TextArea  placeholder='Write...'/> */}
                    <TextArea
                      name={["primary_object", "description"]}
                      // maxLength={100}
                      // onChange={onChange}
                      placeholder="Write..."
                      style={{
                        height: 120,
                        resize: "none",
                      }}
                    />
                  </Form.Item>

                

                  <Form.Item
                    name={["primary_object", "is_active"]}
                    className="isoInputWrapper"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Switch
                      checked={isAllow === 1} // Set based on isAllow value
                      onChange={(checked) => handleChangeAdHoch(checked)}
                    />{" "}
                    <span className="activeClass">
                      {isAllow === 1 ? "Active" : "Inactive"}
                    </span>
                  </Form.Item>
                </div>
              </Form>
            }
          </TabPane>
          </>
        ):('') 
        }
         
          {/* {props.edit && ( */}
          <TabPane
            tab={<IntlMessages id="propertyPage.mobModal.historyTab" />}
            key={modalData.viewHistoryVisible ? "1":"2"} 
          >
          {modalData?.viewHistoryVisible ?
          '' :
          <Row>
              <Col xs={{ span: 7 }} md={{ span: 7 }}>
                {(activePermission == "owner" ||
                  activePermission == "admin" ||
                  activePermission == "modify") && (
                  <Button
                    key="submit"
                    className="btn-create-crop"
                    type="primary"
                    onClick={addHistory}
                  >
                    {
                      <IntlMessages id="propertyPage.mobModal.historyTab.addHistory" />
                    }
                  </Button>
                )}
              </Col>
              <Col xs={{ span: 15 }} md={{ span: 17 }}></Col>
            </Row>
          }
           
            <br />
            {activeTab == (modalData.viewHistoryVisible ? "1":"2")  && (
              <PropertyAreaHistory
                propertyAreaHistory={propertyAreaHistory}
                updateDataAreaHistory={updateDataAreaHistory}
                dataId={dataObject.id}
             
              />
            )}
          </TabPane>
          {/* )} */}
          {!modalData.viewHistoryVisible === true ? (
            <>
            
            {activePropertySubscription === process.env.REACT_APP_STRIPE_PLAN_GOLD ||
  props.edit ==='true' ? (
    <TabPane tab={<IntlMessages id="propertyPage.modal.planning" />} key="3">
      <Row>
        <Col xs={{ span: 7 }} md={{ span: 7 }}>
          <Button
            key="submit"
            className="btn-create-crop"
            type="primary"
            onClick={addPlan}
          >
            Add Plan
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 24 }}>
          <Paddock
            primaryObjects={props.primaryObjects}
            dataId={dataObject.id}
            getListPaddock={getListPaddock}
            dataPaddock={dataPaddock}
          />
        </Col>
      </Row>
    </TabPane>
  ):''}
            </>
          ):('')} 
        

        </Tabs>
        <AddPlanModal
          primaryObjects={props.primaryObjects}
          dataObject={dataObject}
          dataId={dataObject.id}
          cancelPlanModal={cancelPlanModal}
          visiblePlanModal={visiblePlan}
          getListPaddock={getListPaddock}
        />
        <AddAreaHistoryModal
          visibleHistory={visibleHistory}
          model="areas"
          type="area"
          primaryObjects={props.primaryObjects}
          cancelModal={cancelModalHistory}
          propertyId={props.propertyId}
          dataId={dataObject.id}
          updateAreaHistoryData={updateDataAreaHistory}
        />
      </PrimaryObjectModalWrapper>
      <CropModalWrapper
        visible={visibleCrop}
        onCancel={cancelModalCrop}
        maskClosable={false}
        centered
        title={<IntlMessages id="propertyPage.modal.crop.addCrop.title" />}
        footer={[
          <Button
            key="back"
            onClick={cancelModalCrop}
            className="cancel-button"
          >
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
          <Button
            key="submit"
            className="btn-success"
            type="primary"
            onClick={handleCropSubmit}
            loading={loadingCrop}
          >
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>,
        ]}
      >
        {loading ? (
          <Loader />
        ) : (
          <Form
            form={cropForm}
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
            <div className="form-body">
              <Form.Item
                name={["crop", "name"]}
                className="isoInputWrapper"
                label="Crop"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                    whitespace: true,
                  },
                ]}
              >
                <Input size="large" placeholder="Input crop" />
              </Form.Item>
            </div>
          </Form>
        )}
      </CropModalWrapper>
      <ObjectTypeModalWrapper
        visible={visibleObjectType}
        onCancel={cancelModalObjectType}
        maskClosable={false}
        centered
        title={
          <IntlMessages id="propertyPage.modal.primaryObjectType.addType.title" />
        }
        footer={[
          <Button
            key="back"
            onClick={cancelModalObjectType}
            className="cancel-button"
          >
            {<IntlMessages id="propertyPage.modal.cancel" />}
          </Button>,
          <Button
            key="submit"
            className="save-button"
            type="primary"
            onClick={handleObjectTypeSubmit}
            loading={loadingObjectType}
          >
            {<IntlMessages id="propertyPage.modal.save" />}
          </Button>,
        ]}
      >
        {loading ? (
          <Loader />
        ) : (
          <Form
            form={primaryObjectTypeForm}
            layout="vertical"
            scrollToFirstError
            fields={fields}
          >
            <div className="form-body">
              <Form.Item
                name={["primary_object_type", "name"]}
                className="isoInputWrapper"
                label="Type"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                    whitespace: true,
                  },
                ]}
              >
                <Input size="large" placeholder="Input type" />
              </Form.Item>
            </div>
          </Form>
        )}
      </ObjectTypeModalWrapper>
    </>
  );
};

export default PrimaryObjectModal;

const ColorItemRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 30px;
  div {
    margin: 4px;
  }
`;

const ColorItemPreview = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;

const SuffixAddon = styled.span`
  margin-top: 30px;
  margin-bottom: 5px;
  line-height: 38px;
  color: #747784 !important;
  flex-shrink: 0;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-right-width: 1px !important;
  min-width: 50px;
  background-color: #f8f9fa;
  border: solid #d9d9d9;
  border-width: 1px 0;
  white-space: nowrap;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
`;
const AddonInput = styled.div`
  display: flex;
  align-items: flex-start;
  .ant-row {
    width: 100%;
  }
`;
const AcreageInput = styled.div`
  display: flex;
  align-items: flex-start;
`;