const stripeActions = {
  TRIGGER_STRIPE: 'TRIGGER_STRIPE',
  END_TRIGGER_STRIPE: 'END_TRIGGER_STRIPE',
  SET_TOKEN: 'SET_TOKEN',
  SET_CARD_TOKEN: 'SET_CARD_TOKEN',
  SET_ERROR: 'SET_ERROR',

  triggerStripe: () => ({
    type: stripeActions.TRIGGER_STRIPE
  }),
  endTriggerStripe: () => ({
    type: stripeActions.END_TRIGGER_STRIPE
  }),
  setToken: (token) => ({
    type: stripeActions.SET_TOKEN,
    token,
  }),
  setCardToken: (cardToken) => ({
    type: stripeActions.SET_CARD_TOKEN,
    cardToken,
  }),
  setError: (error) => ({
    type: stripeActions.SET_ERROR,
    error
  })
};

export default stripeActions;